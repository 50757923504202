import { format } from 'date-fns';

import { CSVEntry } from '@/utils/getCSV';
import type { StatusesQuery } from '@/generated/shared-graphql';

export const HEADERS = [
  'Id',
  'Jurisdiction',
  'Display date',
  'Scheduled date',
  'Status',
] as const;

type Header = typeof HEADERS[number];
type MapDataForCsvReturn = Array<CSVEntry<any, Header>>;

const mapDataForCSV = (data: StatusesQuery): MapDataForCsvReturn => {
  const { edges } = data.statusFieldsesConnection;

  return edges?.reduce<MapDataForCsvReturn>?.((accumulator, edge) => {
    if (!edge) {
      return accumulator;
    }

    const result: MapDataForCsvReturn = [{
      Id: edge.node.id,
      Jurisdiction: edge.node.locations?.[0]?.fields?.[0]?.name,
      'Display date': format(new Date(edge.node.date), `${edge.node.adHoc ? 'do' : ''} MMMM yyyy`).trim(),
      'Scheduled date': format(new Date(edge.node.publishDate), `${edge.node.adHoc ? 'do' : ''} MMMM yyyy`).trim(),
      Status: edge.node.published ? 'Published' : 'Not published',
    }];

    return accumulator.concat(result);
  }, []) ?? [];
};

export default mapDataForCSV;
