import { Parser } from 'json2csv';

export type CSVEntry<
  CSVCellData,
  Headers extends string = string
> = Partial<Record<Headers, CSVCellData>>;

const getCSV = <
  CSVCellData,
  Headers extends string = string,
  ArrayEntry extends CSVEntry<CSVCellData, Headers> = CSVEntry<CSVCellData, Headers>,
>(fields: Array<Headers>, data: Array<ArrayEntry>): string => {
  const parser = new Parser<ArrayEntry>({ fields });
  const csv = parser.parse(data);
  return csv;
};

export default getCSV;
