import streamSaver from 'streamsaver';

const downloadFile = async (filename: string, data: string) => {
  const uInt8 = new TextEncoder().encode(data);
  const fileStream = streamSaver.createWriteStream(filename, {
    size: uInt8.byteLength,
  });

  const writer = fileStream.getWriter();
  await writer.write(uInt8);
  await writer.close();
};

export default downloadFile;
